const install = function (Vue, options) {
  Vue.mixin({
    computed: {
      $schema() {
        return this.$store.state.Db.schema.merge
      },
      $features () {
        let features = { workflow: {}, integration: [] }
        let gates = this.$store.state.Auth.featureGates
        for (const [key, value] of Object.entries(gates)) {
          features[key] = value
        }
        let setup = this.$schema?.setup || {}
        let featureSetup = setup.features || []
        let featureSelect = this.$store.state.Auth.featureSelect || []
        featureSelect.forEach((f) => {
          if(typeof gates[f] !== 'undefined') {
            if(gates[f] && featureSetup.includes(f)) features[f] = true
            else features[f] = false
          }
          else {
            if(featureSetup.includes(f)) features[f] = true
            else features[f] = false
          }
        })
        if((setup.idSetup || []).includes('point')) features.points = true
        if((setup.idSetup || []).includes('nfc-uid')) features.nfctags = true

        let workflow = this.$store.getters.unitSettings.workflow || []
        let isAdmin = this.$store.getters.isAdmin
        workflow.forEach(w => { 
          if(w === 'adminOptions' && !isAdmin) return
          features.workflow[w] = true 
        })

        let integrations = new Set()
        this.$store.state.Db.integrations.forEach(i => {
          if(i.archived || !i.enabled) return
          if(i.type === 'webhook') i.triggers.forEach(t => { integrations.add(t) })
        })
        features.integration = Array.from(integrations)
        return features
      },
    }
  })
}
export default { install }
