import { LogEvent } from "../store/analytics"

export default {
    methods: {
      logEvent(event, parameters) {
        LogEvent(event, parameters)
      },
      logPage(data) {
        let properties = Object.assign({}, data || {}, {
          page_title: this.$route.name, // route name value
          page_path: this.$route.path, // route path value
          page_location: window.location.href // window.location.href
        }) 
        LogEvent('page_view', properties)
      }
    }
  }