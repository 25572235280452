export default {
  data() {
    return {
    }
  },
  methods: {
    dateString(value, format) {
      let d = value ? new Date(value) : new Date()
      if(!d) return null
      let date = d.getDate()
      let month = d.getMonth() + 1
      let year = d.getYear()
      let fullyear = d.getFullYear()
      let hour = d.getHours()
      let minutes = d.getMinutes()

      month = month < 10 ? '0' + month : month;
      date = date < 10 ? '0' + date : date;
      year = year > 100 ? year - 100 : year
      hour = hour < 10 ? '0' + hour : hour
      minutes = minutes < 10 ? '0' + minutes : minutes

      if(format === 'yyyy') return `${fullyear}`
      else if(format === 'yyyy-mm') return `${fullyear}-${month}`
      else if(format === 'yyyy-mm-dd') return `${fullyear}-${month}-${date}`
      else if(['long', true].includes(format)) return `${date}/${month}/${fullyear} ${hour}:${minutes}`
      return `${date}/${month}/${year}`
    },
  }
}