<template>
  <div>
    <b-modal v-model="search.modal" @hide="close" ok-variant="info" :title="'PointID ' + search.point" ok-only centered scrollable>
      <div class="mb-3">
        <strong>Current Assets</strong>
        <div v-if="search.current.length === 0" class="mt-1" style="padding: 1px" >No current assets found.</div>
        <div v-else class="mt-1" >
          <b-table striped hover :items="search.current" :fields="['assetID','Info','Start']" small>
            <template v-slot:cell(assetID)="data">
              <div style="max-width:100px">
                <b-link  @click="goAsset(data.item.aid)">
                  {{ search.assets[data.item.aid].assetID }}
                </b-link>
              </div>
            </template>
            <template v-slot:cell(Info)="data">
              <div class="flex-row">
                <div>
                  <div style="overflow:hidden; white-space: nowrap;">{{ search.assets[data.item.aid].type }}</div>
                  <div>{{ search.assets[data.item.aid].label }}</div>
                </div>
              </div>
            </template>
            <template v-slot:cell(start)="data">
              <div style="max-width:100px">
                <span style="margin-left: 5px">
                  {{ dateString(data.item.start, 'yyyy-mm-dd') }}
                </span>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div>
        <strong>Past Assets</strong>
        <div class="mt-1" style="padding: 1px" v-if="search.past.length === 0">No past assets found.</div>
        <div v-else class="mt-1" style="position: relative; width: 100%">
          <b-table striped hover :items="search.past" :fields="['assetID','Info','Start']" small>
            <template v-slot:cell(assetID)="data">
              <div style="max-width:100px">
                <b-link @click="goAsset(data.item.aid)">
                  {{ search.assets[data.item.aid].assetID }}
                </b-link>
              </div>
            </template>
            <template v-slot:cell(Info)="data">
              <div class="flex-row" style="width: calc(100% - 200px)">
                <div>
                  <div style="overflow:hidden; white-space: nowrap;">{{ search.assets[data.item.aid].type }}</div>
                  <div>{{ search.assets[data.item.aid].label }}</div>
                </div>
              </div>
            </template>
            <template v-slot:cell(start)="data">
              <div style="width:100px">
                <span style="white-space: nowrap">
                  {{ dateString(data.item.start, 'yyyy-mm-dd') }}
                </span>
                 to 
                <span style="white-space: nowrap">
                  {{ dateString(data.item.end, 'yyyy-mm-dd') }}
                </span>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
/*
PointSearch documentation:
Initiate search for Point Ids and display results in modal.

Monitors 'value' prop for changes and initialises search.
Emits 'asset' event with selected assetID... immediately if only one exists

*/
import { searchPoint } from '@/store/tsSearch'
import DateTime from '@/mixins/datetime'
import Alert from '@/mixins/alert'

export default {
  name: 'PointSearch',
  props: {
    value: String,
    navigate: { type: Boolean, default: false },
    noModal: { type: Boolean, default: false },
    label: { type: [String, Boolean], default: true },
  },
  mixins: [ DateTime, Alert ],
  emits: ['input', 'asset'],
  data() {
    return {
      search: {
        modal: false,
        point: null,
        assets: {},
        current: [],
        past: []
      }
    }
  },
  methods: {
    async getPoint(p) {
      let { assets, points } = await searchPoint(p)
      this.search.point = p;
      let current = []
      let past = []
      Object.values(points).forEach(r => {
        if(r.end) past.push(r)
        else current.push(r)
      })
      current.sort((a, b) => a.start - b.start)
      past.sort((a, b) => a.end - b.end)
      this.search.assets = assets
      this.search.current = current
      this.search.past = past
      // console.log(this.search)
      if(current.length === 1) {
        let aid = current[0].aid
        if(this.noModal) this.goAsset(aid)
        else this.$emit('asset', this.search.assets[aid].assetID)
      }
      if (!this.noModal) this.search.modal = true 
      else {
        if(this.current.length === 0) this.setAlert('No current asset')
        else this.setAlert('Multiple assets found')
      }
    },
    goAsset(aid) {
      let asset = this.search.assets[aid]
      this.$emit('asset', asset.assetID)
      
      if(this.navigate) {
        let currentUnit = this.$store.state.Db.unit.id
        if(asset._unitID !== currentUnit) {
          this.setAlert('Switching Unit', 'warning')
          console.log('Switching unit to ', asset._unitID)
          this.$store.dispatch('switchUnit', asset._unitID)
        }
        if(this.$route.path === `/home/asset/${this.search.assets[aid].assetID}`) console.log('Already on asset page')
        else this.$router.push(`/home/asset/${this.search.assets[aid].assetID}`)
      }
      this.close()
    },
    close() {
      this.search.modal = false
      this.$emit('input', null)
      // this.value = null
    }
  },
  watch: {
    value: {
      handler(val) {
        if(val !== null) this.getPoint(val)
      },
      immediate: true
    }
  }
}
</script>